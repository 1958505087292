.upload-logo {
  .ant-upload {
    width: 100%;
    height: 180px;
    margin-top: 10px;
    max-width: 200px;
  }

  img {
    object-fit: cover;
    max-height: 100%;
    max-width: 100%;
  }
}

.app-detail-whitelist:before {
  content: "";
  display: block;
}

.application-list {
  .application-item {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
    }
    .main-info {
      flex-grow: 1;
      padding: 24px;
    }
    .addition-info {
      border-top: 1px solid #f0f0f0;
      padding: 12px 24px;
    }
  }
}
.applicaiton-url-webhook {
  font-weight: 500;
  white-space: nowrap;
}

