.img-logo-shipping-partner {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin-right: 6px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    display: block;
    background-color: #d9d9d9;
}
._snappy {
    background-image: url('../../../resources/img/shipping_provider/snappy.png');
    background-size: 16px 16px;
}
._jnt {
    background-image: url('../../../resources/img/shipping_provider/jnt.png');
    background-size: 16px 16px;
}
._jntp {
    background-image: url('../../../resources/img/shipping_provider/jntp.png');
    background-size: 16px 16px;
}
._ghn {
    background-image: url('../../../resources/img/shipping_provider/ghn.png');
    background-size: 16px 16px;
}