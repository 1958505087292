.list-products-table {
	.ant-table-thead > tr > th {
		color: #0148ad !important;
		background-color: #f4f7fd !important;
	}

	.ant-table-tbody .ant-table-row {
		&:hover {
			td {
				background-color: #e3f5ff;
			}
		}
		&:nth-child(even) {
			background: #f6fbff;
		}
	}
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	padding-right: 10px !important;
	padding-left: 10px !important;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}
