$color-text-menu: #cee2ff;
$color-text-breadcrumb: #666666;

#root {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  align-items: stretch;
}

.site-logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
  cursor: pointer;
}

.site-content {
  padding: 1.5rem;
  @include media-breakpoint-down(sm) {
    padding: 10px;
    header.ant-layout-header {
      height: auto;
      padding: 20px 50px;
      line-height: 32px;

      h2 {
        font-size: 16px;
      }
    }
  }
}

.box-shadow {
  box-shadow: 1px 1px 1px rgba(99,99,99,.1);
}

.bg-white {
  background: $bg-color--white;
}

.bg-light-gray {
  background: $bg-color--grayf9;
}

.bg-grayc {
  background: $bg-color--grayf2;
}

.bg-blue {
  background: #2e90e8;
}

.bg-orange {
  background: $color--pending-status;
}

#dashboard-body {
  margin-top: 60px;
}

#page-body {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}

.main-menu {
  background: $color--primary;
  color: #ffffff;
  height: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;

    &:horizontal {
      height: 3px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, .4);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, .6);
    cursor: pointer;
  }

  @include media-breakpoint-down(md) {
    &::-webkit-scrollbar {
      width: 3px;

      &:horizontal {
        height: 3px;
      }
    }
    &::-webkit-scrollbar-thumb {
      background: #e2e5ec;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #e2e5ec;
      cursor: pointer;
    }
  }

  .member-menu {
    border-bottom: 1px solid rgba(233, 242, 255, .1);
    height: 70px;

    > span {
      width: 45px;
      height: 45px;
      display: block;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid #ffffff;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .user-info {
      width: auto;
      overflow: hidden;
      transition: all .2s;
      white-space: nowrap;

      p a {
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
      }

      > a {
        font-size: 12px;
        color: #00c7ff;
      }
      p {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .member-menu div {
      width: 0;
    }
  }
}

.toggle-menu {
  position: relative;
  margin: 0 26px;
  height: 17px;
  width: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    display: block;
    width: 26px;
    height: 1px;
    background: #ffffff;
    position: absolute;

    &:before, &:after {
      height: 1px;
      width: 100%;
      background: #fff;
      content: "";
      position: absolute;
      left: 0;
      transition: all .3s;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }

  &.expanded {
    span {
      background: transparent;

      &:before {
        top: 0;
        transform: rotate(45deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(-45deg);
      }
    }
  }
}

.left-menu {
  .ant-menu {
    background: transparent;

    li.ant-menu-item, li.ant-menu-submenu > div {
      display: flex;
      align-items: center;
    }
  }

  > .ant-menu {
    padding-top: 15px;
  }
}

.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #ffffff !important;
}

.toggle-submenu {
  position: absolute;
  bottom: 21px;
  left: 100%;
  z-index: 9;
  cursor: pointer;
  margin: 0;
  height: 40px;
  width: 40px;
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
  background-color: $color--primary;

  span {
    display: block;
    width: 26px;
    height: 1px;
    background: #ffffff;
    position: absolute;
    left: 6px;

    &:before, &:after {
      height: 1px;
      width: 100%;
      background: #fff;
      content: "";
      position: absolute;
      left: 0;
      transition: all .3s;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }
  }
}

.expanded-mobile {
  span {
    background: transparent;

    &:before {
      top: 0;
      transform: rotate(45deg);
    }

    &:after {
      bottom: 0;
      transform: rotate(-45deg);
    }
  }
}

.left-menu .ant-menu-dark ul.ant-menu-inline.ant-menu-sub {
  background: transparent !important;
}

.left-menu .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, .2);
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: $color--primary !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, .1) !important;
}

/*---------------header----------------*/

.page-header {
  width: 100%;
  height: 70px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .1);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9;
  padding: 0 20px 5px 20px;
  top: 0;

  &.ant-layout-header {
    line-height: 38px;
  }

  h2 {
    color: $color--primary;
    font-size: 24px;
  }
}

.breadcrumb-header {
  color: $color-text-breadcrumb;
  position: relative;
  font-size: 12px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    content: "";
  }

  span {
    color: $color-text-breadcrumb;
  }

  a {
    color: $color-text-breadcrumb;

    &:hover {
      color: $color--primary;
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;

  .main-navbar {
    display: flex;

    a {
      font-size: 24px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 70px;
      background: rgba(255, 255, 255, .1);
      margin-right: 1px;

      &:hover {
        background: rgba(255, 255, 255, .2);
      }
    }
  }

  .member-navbar {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;

    > a {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 60px;

      img {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 100%;
        -webkit-border-radius: 100%;
        -moz-border-radius: 100%;
        border: 2px solid #ffffff;
      }

      &:hover {
        background: rgba(255, 255, 255, .3);

        img {
          border-color: #ffffff;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      flex-grow: 2;
      font-weight: normal;

      a {
        color: #ffffff;
        display: block;
        line-height: 60px;
        text-align: center;
      }
    }
    .main-navbar {
      flex-grow: 8;
    }
  }
}

.sub-navbar {
  .ant-dropdown-arrow {
    border-top-color: $color--primary;
    border-left-color: $color--primary;
  }

  ul {
    width: 240px;
    background: $color--primary;
    padding: 10px 15px;
    box-shadow: -2px 2px 0 0 rgba(0, 0, 0, .2);

    li {
      & + li {
        border-top: 1px solid rgba(255, 255, 255, .1);
      }

      &:hover {
        background: transparent;
      }

      a {
        color: $color-text-menu;
        line-height: 32px;
        display: block;

        &:hover {
          color: #ffffff;
          background: transparent;
        }
      }
    }
  }
}

.title-page {
  > h2 {
    text-transform: uppercase;
  }
}

.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px !important;
}

.bottom-toggle-sidebar {
  background-color: rgba(255, 255, 255, .2);
  cursor: pointer;
}
