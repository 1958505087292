//@import '~antd/dist/antd.css';
@import "custom-antd.css";
@import "bootstrap/bootstrap";
@import "generics/generic.box-sizing";
@import "generics/generic.normalize";
@import "generics/generic.reset";
@import "settings/setting.color";
@import "settings/setting.text";
@import "settings/setting.border";
@import "settings/setting.table";
@import "components/layout";
@import "components/application";
@import "components/billOfLading.css";
